import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [article, setArticle] = useState(null);
  const [timeLeft, setTimeLeft] = useState(20);
  const [dateTime, setDateTime] = useState('');
  const [articleCount, setArticleCount] = useState(0);
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    fetchArticle();
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          fetchArticle();
          return 20;
        }
        return prevTime - 1;
      });
    }, 1000);

    const dateTimer = setInterval(() => {
      updateDateTime();
    }, 1000);

    return () => {
      clearInterval(timer);
      clearInterval(dateTimer);
    };
  }, []);

  const fetchArticle = async () => {
    try {
      const response = await fetch('/api/article');
      const data = await response.json();
      if (data.Blocked) {
        setIsBlocked(true);
      } else {
        setArticle(data);
        setArticleCount(data.ArticleCount);
        if (data.ArticleCount >= 5) {
          setIsBlocked(true);
        }
      }
    } catch (error) {
      console.error('Error fetching article:', error);
    }
  };

  const updateDateTime = () => {
    const now = new Date();
    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    setDateTime(now.toLocaleDateString('en-US', options));
  };

  const sendEmail = async () => {
    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });
      
      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      alert('Email sent successfully! The website has been unblocked.');
      setName('');
      setEmail('');
      setMessage('');
      setIsBlocked(false);
      setArticleCount(0);
      fetchArticle();
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again.');
    }
  };

  if (isBlocked) {
    return (
      <div className="App blocked">
        <header>
          <h1>BBC News Spotlight</h1>
          <div>{dateTime}</div>
        </header>
        <main>
          <div className="blocked-message">
            <h2>Access Blocked</h2>
            <p>You have viewed 5 articles. Please send us a message to unblock the website.</p>
          </div>
          <div className="email-section">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Your Name"
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your Email"
            />
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
            />
            <button onClick={sendEmail} className="email-button">Send message to unblock</button>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="App">
      <header>
        <img src="/bbc-logo.svg" alt="BBC Logo" className="logo" />
        <h1>News Spotlight</h1>
        <div>{dateTime}</div>
      </header>
      {article && (
        <main>
          <article className="news-card">
            <h2>{article.Title}</h2>
            <p>{article.Description}</p>
            <a href={article.URL} target="_blank" rel="noopener noreferrer" className="read-more">
              Read full article
            </a>
            <div className="article-info">
              <div className="article-count-container">
                <div className="article-count-bar" style={{ width: `${Math.min(articleCount / 100 * 100, 100)}%` }}></div>
                <span className="article-count-text">{articleCount} articles displayed</span>
              </div>
              <div className="timer-container">
                <svg className="timer-svg" viewBox="0 0 100 100">
                  <circle className="timer-circle" cx="50" cy="50" r="45"></circle>
                  <circle
                    className="timer-progress"
                    cx="50"
                    cy="50"
                    r="45"
                    style={{
                      strokeDasharray: `${2 * Math.PI * 45} ${2 * Math.PI * 45}`,
                      strokeDashoffset: `${2 * Math.PI * 45 * (1 - timeLeft / 20)}`,
                    }}
                  ></circle>
                </svg>
                <div className="timer-text">{timeLeft}</div>
              </div>
            </div>
          </article>
        </main>
      )}
      <div className="email-section">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Your Name"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your Email"
        />
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message here..."
        />
        <button onClick={sendEmail} className="email-button">Send us a message</button>
      </div>
      <footer>
        <p>Client IP: {article?.ClientIP} | Pod Host: {article?.PodHost}</p>
      </footer>
    </div>
  );
}

export default App;
